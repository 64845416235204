<template>
  <v-main>
    <div
      id="wrapper_skills"
      v-resize="onResize"
      class="d-flex align-center justify-center"
    >
      <v-carousel height="100%">
        <v-carousel-item v-for="(project, i) in this.projects" :key="i">
          <v-container fill-height>
            <v-row justify="center">
              <v-col cols="9" lg="4" md="6" sm="8">
                <SkillCard
                  :topic="project.title"
                  :desc="project.desc"
                  :personal="project.personal"
                >
                </SkillCard>
              </v-col>
            </v-row>
          </v-container>
        </v-carousel-item>
      </v-carousel>
    </div>
  </v-main>
</template>

<script>
import SkillCard from "../components/SkillCard.vue";

export default {
  name: "Skills",
  components: {
    SkillCard
  },
  data: () => ({}),
  methods: {
    onResize() {
      let vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
  },
  computed: {
    bp() {
      console.log(this.$vuetify.breakpoint.name);
      return 100;
    },
    projects() {
      return require("../config/projects.json");
    }
  },
  mounted() {
    this.onResize();
  }
};
</script>

<style>
#wrapper_skills {
  height: 100vh;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
}
#itemHolder {
  height: 100%;
}
</style>
