<template>
  <v-main>
    <div id="wrapper_desc" v-resize="onResize" class="bgColor">
      <v-container fill-height fluid id="content">
        <v-row class="mb-5" justify="center">
          <v-col cols="12" md="6" sm="9" xs="12" class="d-flex justify-center">
            <v-card
              class="pa-4"
              width="100%"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1000"
              elevation="4"
            >
              <p class="black--text" :class="font_size">
                Hey and welcome, thanks for checking out my page! <br />My name
                is Yasin. Currently I am studying computer science at
                Ravensburg-Weingarten Unversity. After gathering experience
                working with teams in my previous job as an educator in a
                psychiatric hospital I decided to reorientate. Quickly I
                realised, that this was the right desicion.<br />With great
                passion and interest I study the different topics of computer
                science. I'm thrilled to keep learning about new technologies
                (especially in AI and autonomous vehicles) and their
                applications and hopefully will contribute to the rapid
                development we experience today in my future job. <br />Check
                out what skills I aquired so far below!
              </p>
            </v-card>
          </v-col>
          <v-col
            v-if="!height_to_low"
            cols="12"
            xs="12"
            class="d-flex justify-center"
          >
            <v-img
              data-aos="zoom-out"
              data-aos-delay="300"
              data-aos-duration="800"
              class="rounded-circle"
              v-bind:max-height="picture_size"
              v-bind:max-width="picture_size"
              src="../../public/profile_pic.jpg"
              position="50% 20%"
            >
            </v-img>
          </v-col>
        </v-row>
        <div class="btWrapper text-center" v-on:click="emitClick">
          <v-btn fab outlined color="grey darken-1">
            <v-icon>
              mdi-arrow-down
            </v-icon>
          </v-btn>
        </div>
      </v-container>
    </div>
  </v-main>
</template>

<script>
export default {
  name: "Description",
  components: {},
  props: {},
  data: () => ({
    height_to_low: false
  }),
  methods: {
    emitClick() {
      this.$emit("toSkills");
    },
    onResize() {
      if (window.innerHeight < 600) {
        this.height_to_low = true;
      } else {
        this.height_to_low = false;
      }
      let vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
  },
  computed: {
    picture_size() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 125;
        default:
          return 200;
      }
    },
    font_size() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "text-body-2";
        default:
          return "text-body-1";
      }
    }
  },
  mounted() {
    this.onResize();
  }
};
</script>

<style>
#wrapper_desc {
  height: 100vh;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
}
</style>
