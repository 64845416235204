<template>
  <v-main>
    <v-card
      color="white"
      elevation="4"
      min-height="66vh"
      max-height="85vh"
      class="pa-5 rotBody"
      v-bind:class="flip ? 'rotate' : ''"
    >
      <div class="front py-4">
        <v-img
          :src="url"
          v-bind:max-height="responsiveIcon"
          v-bind:min-height="responsiveIcon"
          contain
          class="rotBody imgBody"
          v-bind:class="flip ? 'rotate' : ''"
        />
        <v-card-title
          class="d-flex justify-center font-weight-light black--text"
          v-bind:class="responsiveFont"
        >
          <p>{{ topic }}</p>
          <sup
            v-if="topic === 'ROS'"
            class="rotBody imgBody"
            v-bind:class="flip ? 'rotate' : ''"
            >&trade;</sup
          >
        </v-card-title>
        <v-divider light />
        <v-card-text>
          <p class="black--text text-overline">What I've done:</p>
          <p class="black--text text-body-1 break-line" :class="font_size">
            {{ personal }}
          </p>
        </v-card-text>
        <v-card-actions class="btAction">
          <v-btn
            class="imgBody"
            v-bind:class="flip ? 'rotate' : ''"
            small
            fab
            elevation="0"
            color="white"
            v-on:click="flip = !flip"
          >
            <v-icon class="" color="grey">mdi-help-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </div>
      <div class="back py-4">
        <v-card-text>
          <p class="black--text text-overline">What is {{ topic }}:</p>
          <p class="black--text break-line" :class="font_size">{{ desc }}</p>
        </v-card-text>
        <v-card-actions class="btAction">
          <v-btn
            class="imgBody"
            v-bind:class="!flip ? 'rotate' : ''"
            small
            fab
            elevation="0"
            color="white"
            v-on:click="flip = !flip"
          >
            <v-icon class="" color="grey">mdi-rotate-left</v-icon>
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-main>
</template>

<script>
export default {
  name: "SkillCard",
  components: {},
  props: {
    topic: String,
    desc: String,
    personal: String,
    info: String
  },
  data: () => ({
    flip: false
  }),
  methods: {},
  computed: {
    url() {
      return require("../../public/" + this.topic + "_icon.svg");
    },
    responsiveFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "text-h3";
        default:
          return "text-h2";
      }
    },
    responsiveIcon() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100";
        default:
          return "150";
      }
    },
    font_size() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "text-body-2";
        default:
          return "text-body-1";
      }
    }
  }
};
</script>

<style>
.rotBody {
  transition: 1s;
  transform-style: preserve-3d;
}
.imgBody {
  backface-visibility: hidden;
}
.rotate {
  transform: rotateY(180deg);
}
.front,
.back {
  backface-visibility: hidden;

  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform: rotateX(0deg);
}
.back {
  transform: rotateY(180deg);
}
.btAction {
  position: absolute;
  bottom: 0;
  right: 0;
}
.break-line {
  white-space: pre-line;
}
</style>
