<template>
  <v-app id="main">
    <Home
      v-on:toDescription="$vuetify.goTo(scroll_destination, scroll_options)"
    />
    <Description
      id="desc"
      v-on:toSkills="$vuetify.goTo(scroll_skills, scroll_options)"
    />
    <Skills id="skills" />
    <Imprint />
  </v-app>
</template>

<script>
import Home from "./views/Home.vue";
import Description from "./views/Description.vue";
import Skills from "./views/Skills.vue";
import Imprint from "./views/Imprint.vue";

export default {
  name: "App",
  components: {
    Home,
    Description,
    Skills,
    Imprint
  },
  props: {},
  data: () => ({
    scroll_destination: "#desc",
    scroll_skills: "#skills",
    scroll_options: {
      duration: 800,
      offset: 0,
      easing: "easeInOutCubic"
    }
  }),
  methods: {},
  computed: {},
  mounted() {
    document.title = "Kosy Portfolio";
  }
};
</script>

<style>
/* roboto-300 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/roboto-v20-latin-300.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/roboto-v20-latin-300.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/roboto-v20-latin-300.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/roboto-v20-latin-300.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/roboto-v20-latin-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/roboto-v20-latin-300.svg#Roboto")
      format("svg"); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/roboto-v20-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/roboto-v20-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/roboto-v20-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/roboto-v20-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/roboto-v20-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/roboto-v20-latin-regular.svg#Roboto") format("svg"); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/roboto-v20-latin-500.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/roboto-v20-latin-500.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/roboto-v20-latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/roboto-v20-latin-500.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/roboto-v20-latin-500.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/roboto-v20-latin-500.svg#Roboto")
      format("svg"); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/roboto-v20-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/roboto-v20-latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/roboto-v20-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/roboto-v20-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/roboto-v20-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/roboto-v20-latin-700.svg#Roboto")
      format("svg"); /* Legacy iOS */
}
</style>
