<template>
  <v-main>
    <div id="wrapper_imp" v-resize="onResize">
      <v-container fluid fill-height class="d-flex align-start">
        <v-row>
          <v-col cols="12">
            <v-expansion-panels :value="panel_opened">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Impressum
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <h2>Impressum</h2>

                  <h3>Angaben gem&auml;&szlig; &sect; 5 TMG</h3>
                  <p class="text-body-2">
                    Yasin Koschinski<br />
                    Siedlerweg 22/2<br />
                    88214 Ravensburg
                  </p>

                  <h3>Kontakt</h3>
                  <p class="text-body-2">
                    Telefon: +49 (0) 1520 7191712<br />
                    E-Mail: yasin@kosy.dev
                  </p>
                  <h4>Haftung f&uuml;r Inhalte</h4>
                  <p class="text-body-2">
                    Als Diensteanbieter sind wir gem&auml;&szlig; &sect; 7 Abs.1
                    TMG f&uuml;r eigene Inhalte auf diesen Seiten nach den
                    allgemeinen Gesetzen verantwortlich. Nach &sect;&sect; 8 bis
                    10 TMG sind wir als Diensteanbieter jedoch nicht
                    verpflichtet, &uuml;bermittelte oder gespeicherte fremde
                    Informationen zu &uuml;berwachen oder nach Umst&auml;nden zu
                    forschen, die auf eine rechtswidrige T&auml;tigkeit
                    hinweisen.
                  </p>
                  <p class="text-body-2">
                    Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
                    Informationen nach den allgemeinen Gesetzen bleiben hiervon
                    unber&uuml;hrt. Eine diesbez&uuml;gliche Haftung ist jedoch
                    erst ab dem Zeitpunkt der Kenntnis einer konkreten
                    Rechtsverletzung m&ouml;glich. Bei Bekanntwerden von
                    entsprechenden Rechtsverletzungen werden wir diese Inhalte
                    umgehend entfernen.
                  </p>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Sources
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ul>
                    <li class="break_li text-body-2">
                      Waves Video: Waves Ruhsing and Splashing To The Shore by
                      Michal Marek on pexels.com
                    </li>
                    <li class="break_li text-body-2">
                      Vue-Logo: Vue.js Logo 2 by Evan You on
                      commons.wikimedia.org/wiki/File:Vue.js_Logo_2.svg
                      (Creative Commons)
                    </li>
                    <li class="break_li text-body-2">
                      Node-Logo: Node.js Logo Vector on
                      seeklogo.com/vector-logo/273749/node-js (license:
                      nodejs.org/en/about/trademark/)
                    </li>
                    <li class="break_li text-body-2">
                      git-Logo: Git_icon by Jason Long on
                      commons.wikimedia.org/wiki/File:Git_icon.svg (Creative
                      Commons)
                    </li>
                    <li class="break_li text-body-2">
                      Python-Logo: Python-logo-notext by python.org from
                      commons.wikimedia.org/wiki/File:Python_logo_and_wordmark.svg
                      (GNU General Public License)
                    </li>
                    <li class="break_li text-body-2">
                      ROS-Logo: Robot Operating System logo.svg by Chapulinaaa
                      on
                      commons.wikimedia.org/wiki/File:Robot_Operating_System_logo.svg
                      (license: www.ros.org/press-kit/)
                    </li>
                    <li class="break_li text-body-2">
                      Scrum-Icon: Scrum by Rflor on
                      thenounproject.com/term/scrum/376069/
                    </li>
                  </ul>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Datenschutzerklärung
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <h2>Datenschutzerklärung</h2>
                  <h2>Einleitung</h2>
                  <p>
                    Mit der folgenden Datenschutzerklärung möchten wir Sie
                    darüber aufklären, welche Arten Ihrer personenbezogenen
                    Daten (nachfolgend auch kurz als "Daten“ bezeichnet) wir zu
                    welchen Zwecken und in welchem Umfang verarbeiten. Die
                    Datenschutzerklärung gilt für alle von uns durchgeführten
                    Verarbeitungen personenbezogener Daten, sowohl im Rahmen der
                    Erbringung unserer Leistungen als auch insbesondere auf
                    unseren Webseiten, in mobilen Applikationen sowie innerhalb
                    externer Onlinepräsenzen, wie z.B. unserer
                    Social-Media-Profile (nachfolgend zusammenfassend bezeichnet
                    als "Onlineangebot“).
                  </p>
                  <p>
                    Die verwendeten Begriffe sind nicht geschlechtsspezifisch.
                  </p>
                  <p>Stand: 19. November 2020</p>
                  <h2>Inhaltsübersicht</h2>
                  <ul class="index">
                    <li><a class="index-link" href="#m14">Einleitung</a></li>
                    <li>
                      <a class="index-link" href="#m3">Verantwortlicher</a>
                    </li>
                    <li>
                      <a class="index-link" href="#mOverview"
                        >Übersicht der Verarbeitungen</a
                      >
                    </li>
                    <li>
                      <a class="index-link" href="#m13"
                        >Maßgebliche Rechtsgrundlagen</a
                      >
                    </li>
                    <li>
                      <a class="index-link" href="#m27">Sicherheitsmaßnahmen</a>
                    </li>
                    <li>
                      <a class="index-link" href="#m25"
                        >Übermittlung und Offenbarung von personenbezogenen
                        Daten</a
                      >
                    </li>
                    <li>
                      <a class="index-link" href="#m225"
                        >Bereitstellung des Onlineangebotes und Webhosting</a
                      >
                    </li>
                    <li>
                      <a class="index-link" href="#m12">Löschung von Daten</a>
                    </li>
                    <li>
                      <a class="index-link" href="#m15"
                        >Änderung und Aktualisierung der Datenschutzerklärung</a
                      >
                    </li>
                    <li>
                      <a class="index-link" href="#m10"
                        >Rechte der betroffenen Personen</a
                      >
                    </li>
                    <li>
                      <a class="index-link" href="#m42">Begriffsdefinitionen</a>
                    </li>
                  </ul>
                  <h2 id="m3">Verantwortlicher</h2>
                  <p>
                    Yasin Koschinski<br />Siedlerweg 22/2<br />88214 Ravensburg,
                    Deutschland
                  </p>
                  <p>
                    <strong>E-Mail-Adresse:</strong>
                    <a href="mailto:yasin@kosy.dev">yasin@kosy.dev</a>
                  </p>
                  <h2 id="mOverview">Übersicht der Verarbeitungen</h2>
                  <p>
                    Die nachfolgende Übersicht fasst die Arten der verarbeiteten
                    Daten und die Zwecke ihrer Verarbeitung zusammen und
                    verweist auf die betroffenen Personen.
                  </p>
                  <h3>Arten der verarbeiteten Daten</h3>
                  <ul>
                    <li>Inhaltsdaten (z.B. Eingaben in Onlineformularen).</li>
                    <li>
                      Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
                      IP-Adressen).
                    </li>
                    <li>
                      Nutzungsdaten (z.B. besuchte Webseiten, Interesse an
                      Inhalten, Zugriffszeiten).
                    </li>
                  </ul>
                  <h3>Kategorien betroffener Personen</h3>
                  <ul>
                    <li>
                      Nutzer (z.B. Webseitenbesucher, Nutzer von
                      Onlinediensten).
                    </li>
                  </ul>
                  <h3 id="m13">Maßgebliche Rechtsgrundlagen</h3>
                  <p>
                    Im Folgenden teilen wir die Rechtsgrundlagen der
                    Datenschutzgrundverordnung (DSGVO), auf deren Basis wir die
                    personenbezogenen Daten verarbeiten, mit. Bitte beachten
                    Sie, dass zusätzlich zu den Regelungen der DSGVO die
                    nationalen Datenschutzvorgaben in Ihrem bzw. unserem Wohn-
                    und Sitzland gelten können. Sollten ferner im Einzelfall
                    speziellere Rechtsgrundlagen maßgeblich sein, teilen wir
                    Ihnen diese in der Datenschutzerklärung mit.
                  </p>
                  <ul>
                    <li>
                      <strong
                        >Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f.
                        DSGVO)</strong
                      >
                      - Die Verarbeitung ist zur Wahrung der berechtigten
                      Interessen des Verantwortlichen oder eines Dritten
                      erforderlich, sofern nicht die Interessen oder Grundrechte
                      und Grundfreiheiten der betroffenen Person, die den Schutz
                      personenbezogener Daten erfordern, überwiegen.
                    </li>
                  </ul>
                  <p>
                    <strong
                      >Nationale Datenschutzregelungen in Deutschland</strong
                    >: Zusätzlich zu den Datenschutzregelungen der
                    Datenschutz-Grundverordnung gelten nationale Regelungen zum
                    Datenschutz in Deutschland. Hierzu gehört insbesondere das
                    Gesetz zum Schutz vor Missbrauch personenbezogener Daten bei
                    der Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das
                    BDSG enthält insbesondere Spezialregelungen zum Recht auf
                    Auskunft, zum Recht auf Löschung, zum Widerspruchsrecht, zur
                    Verarbeitung besonderer Kategorien personenbezogener Daten,
                    zur Verarbeitung für andere Zwecke und zur Übermittlung
                    sowie automatisierten Entscheidungsfindung im Einzelfall
                    einschließlich Profiling. Des Weiteren regelt es die
                    Datenverarbeitung für Zwecke des Beschäftigungsverhältnisses
                    (§ 26 BDSG), insbesondere im Hinblick auf die Begründung,
                    Durchführung oder Beendigung von Beschäftigungsverhältnissen
                    sowie die Einwilligung von Beschäftigten. Ferner können
                    Landesdatenschutzgesetze der einzelnen Bundesländer zur
                    Anwendung gelangen.
                  </p>
                  <h2 id="m27">Sicherheitsmaßnahmen</h2>
                  <p>
                    Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter
                    Berücksichtigung des Stands der Technik, der
                    Implementierungskosten und der Art, des Umfangs, der
                    Umstände und der Zwecke der Verarbeitung sowie der
                    unterschiedlichen Eintrittswahrscheinlichkeiten und des
                    Ausmaßes der Bedrohung der Rechte und Freiheiten natürlicher
                    Personen geeignete technische und organisatorische
                    Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu
                    gewährleisten.
                  </p>
                  <p>
                    Zu den Maßnahmen gehören insbesondere die Sicherung der
                    Vertraulichkeit, Integrität und Verfügbarkeit von Daten
                    durch Kontrolle des physischen und elektronischen Zugangs zu
                    den Daten als auch des sie betreffenden Zugriffs, der
                    Eingabe, der Weitergabe, der Sicherung der Verfügbarkeit und
                    ihrer Trennung. Des Weiteren haben wir Verfahren
                    eingerichtet, die eine Wahrnehmung von Betroffenenrechten,
                    die Löschung von Daten und Reaktionen auf die Gefährdung der
                    Daten gewährleisten. Ferner berücksichtigen wir den Schutz
                    personenbezogener Daten bereits bei der Entwicklung bzw.
                    Auswahl von Hardware, Software sowie Verfahren entsprechend
                    dem Prinzip des Datenschutzes, durch Technikgestaltung und
                    durch datenschutzfreundliche Voreinstellungen.
                  </p>
                  <p>
                    <strong>SSL-Verschlüsselung (https)</strong>: Um Ihre via
                    unser Online-Angebot übermittelten Daten zu schützen, nutzen
                    wir eine SSL-Verschlüsselung. Sie erkennen derart
                    verschlüsselte Verbindungen an dem Präfix https:// in der
                    Adresszeile Ihres Browsers.
                  </p>
                  <h2 id="m25">
                    Übermittlung und Offenbarung von personenbezogenen Daten
                  </h2>
                  <p>
                    Im Rahmen unserer Verarbeitung von personenbezogenen Daten
                    kommt es vor, dass die Daten an andere Stellen, Unternehmen,
                    rechtlich selbstständige Organisationseinheiten oder
                    Personen übermittelt oder sie ihnen gegenüber offengelegt
                    werden. Zu den Empfängern dieser Daten können z.B.
                    Zahlungsinstitute im Rahmen von Zahlungsvorgängen, mit
                    IT-Aufgaben beauftragte Dienstleister oder Anbieter von
                    Diensten und Inhalten, die in eine Webseite eingebunden
                    werden, gehören. In solchen Fall beachten wir die
                    gesetzlichen Vorgaben und schließen insbesondere
                    entsprechende Verträge bzw. Vereinbarungen, die dem Schutz
                    Ihrer Daten dienen, mit den Empfängern Ihrer Daten ab.
                  </p>
                  <h2 id="m225">
                    Bereitstellung des Onlineangebotes und Webhosting
                  </h2>
                  <p>
                    Um unser Onlineangebot sicher und effizient bereitstellen zu
                    können, nehmen wir die Leistungen von einem oder mehreren
                    Webhosting-Anbietern in Anspruch, von deren Servern (bzw.
                    von ihnen verwalteten Servern) das Onlineangebot abgerufen
                    werden kann. Zu diesen Zwecken können wir Infrastruktur- und
                    Plattformdienstleistungen, Rechenkapazität, Speicherplatz
                    und Datenbankdienste sowie Sicherheitsleistungen und
                    technische Wartungsleistungen in Anspruch nehmen.
                  </p>
                  <p>
                    Zu den im Rahmen der Bereitstellung des Hostingangebotes
                    verarbeiteten Daten können alle die Nutzer unseres
                    Onlineangebotes betreffenden Angaben gehören, die im Rahmen
                    der Nutzung und der Kommunikation anfallen. Hierzu gehören
                    regelmäßig die IP-Adresse, die notwendig ist, um die Inhalte
                    von Onlineangeboten an Browser ausliefern zu können, und
                    alle innerhalb unseres Onlineangebotes oder von Webseiten
                    getätigten Eingaben.
                  </p>
                  <p>
                    <strong>Erhebung von Zugriffsdaten und Logfiles</strong>:
                    Wir selbst (bzw. unser Webhostinganbieter) erheben Daten zu
                    jedem Zugriff auf den Server (sogenannte Serverlogfiles). Zu
                    den Serverlogfiles können die Adresse und Name der
                    abgerufenen Webseiten und Dateien, Datum und Uhrzeit des
                    Abrufs, übertragene Datenmengen, Meldung über erfolgreichen
                    Abruf, Browsertyp nebst Version, das Betriebssystem des
                    Nutzers, Referrer URL (die zuvor besuchte Seite) und im
                    Regelfall IP-Adressen und der anfragende Provider gehören.
                  </p>
                  <p>
                    Die Serverlogfiles können zum einen zu Zwecken der
                    Sicherheit eingesetzt werden, z.B., um eine Überlastung der
                    Server zu vermeiden (insbesondere im Fall von
                    missbräuchlichen Angriffen, sogenannten DDoS-Attacken) und
                    zum anderen, um die Auslastung der Server und ihre
                    Stabilität sicherzustellen.
                  </p>
                  <ul class="m-elements">
                    <li>
                      <strong>Verarbeitete Datenarten:</strong> Inhaltsdaten
                      (z.B. Eingaben in Onlineformularen), Nutzungsdaten (z.B.
                      besuchte Webseiten, Interesse an Inhalten,
                      Zugriffszeiten), Meta-/Kommunikationsdaten (z.B.
                      Geräte-Informationen, IP-Adressen).
                    </li>
                    <li>
                      <strong>Betroffene Personen:</strong> Nutzer (z.B.
                      Webseitenbesucher, Nutzer von Onlinediensten).
                    </li>
                    <li>
                      <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen
                      (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
                    </li>
                  </ul>
                  <h2 id="m12">Löschung von Daten</h2>
                  <p>
                    Die von uns verarbeiteten Daten werden nach Maßgabe der
                    gesetzlichen Vorgaben gelöscht, sobald deren zur
                    Verarbeitung erlaubten Einwilligungen widerrufen werden oder
                    sonstige Erlaubnisse entfallen (z.B., wenn der Zweck der
                    Verarbeitung dieser Daten entfallen ist oder sie für den
                    Zweck nicht erforderlich sind).
                  </p>
                  <p>
                    Sofern die Daten nicht gelöscht werden, weil sie für andere
                    und gesetzlich zulässige Zwecke erforderlich sind, wird
                    deren Verarbeitung auf diese Zwecke beschränkt. D.h., die
                    Daten werden gesperrt und nicht für andere Zwecke
                    verarbeitet. Das gilt z.B. für Daten, die aus handels- oder
                    steuerrechtlichen Gründen aufbewahrt werden müssen oder
                    deren Speicherung zur Geltendmachung, Ausübung oder
                    Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte
                    einer anderen natürlichen oder juristischen Person
                    erforderlich ist.
                  </p>
                  <p>
                    Weitere Hinweise zu der Löschung von personenbezogenen Daten
                    können ferner im Rahmen der einzelnen Datenschutzhinweise
                    dieser Datenschutzerklärung erfolgen.
                  </p>
                  <h2 id="m15">
                    Änderung und Aktualisierung der Datenschutzerklärung
                  </h2>
                  <p>
                    Wir bitten Sie, sich regelmäßig über den Inhalt unserer
                    Datenschutzerklärung zu informieren. Wir passen die
                    Datenschutzerklärung an, sobald die Änderungen der von uns
                    durchgeführten Datenverarbeitungen dies erforderlich machen.
                    Wir informieren Sie, sobald durch die Änderungen eine
                    Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine
                    sonstige individuelle Benachrichtigung erforderlich wird.
                  </p>
                  <p>
                    Sofern wir in dieser Datenschutzerklärung Adressen und
                    Kontaktinformationen von Unternehmen und Organisationen
                    angeben, bitten wir zu beachten, dass die Adressen sich über
                    die Zeit ändern können und bitten die Angaben vor
                    Kontaktaufnahme zu prüfen.
                  </p>
                  <h2 id="m10">Rechte der betroffenen Personen</h2>
                  <p>
                    Ihnen stehen als Betroffene nach der DSGVO verschiedene
                    Rechte zu, die sich insbesondere aus Art. 15 bis 21 DSGVO
                    ergeben:
                  </p>
                  <ul>
                    <li>
                      <strong
                        >Widerspruchsrecht: Sie haben das Recht, aus Gründen,
                        die sich aus Ihrer besonderen Situation ergeben,
                        jederzeit gegen die Verarbeitung der Sie betreffenden
                        personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1
                        lit. e oder f DSGVO erfolgt, Widerspruch einzulegen;
                        dies gilt auch für ein auf diese Bestimmungen gestütztes
                        Profiling. Werden die Sie betreffenden personenbezogenen
                        Daten verarbeitet, um Direktwerbung zu betreiben, haben
                        Sie das Recht, jederzeit Widerspruch gegen die
                        Verarbeitung der Sie betreffenden personenbezogenen
                        Daten zum Zwecke derartiger Werbung einzulegen; dies
                        gilt auch für das Profiling, soweit es mit solcher
                        Direktwerbung in Verbindung steht.</strong
                      >
                    </li>
                    <li>
                      <strong>Widerrufsrecht bei Einwilligungen:</strong> Sie
                      haben das Recht, erteilte Einwilligungen jederzeit zu
                      widerrufen.
                    </li>
                    <li>
                      <strong>Auskunftsrecht:</strong> Sie haben das Recht, eine
                      Bestätigung darüber zu verlangen, ob betreffende Daten
                      verarbeitet werden und auf Auskunft über diese Daten sowie
                      auf weitere Informationen und Kopie der Daten entsprechend
                      den gesetzlichen Vorgaben.
                    </li>
                    <li>
                      <strong>Recht auf Berichtigung:</strong> Sie haben
                      entsprechend den gesetzlichen Vorgaben das Recht, die
                      Vervollständigung der Sie betreffenden Daten oder die
                      Berichtigung der Sie betreffenden unrichtigen Daten zu
                      verlangen.
                    </li>
                    <li>
                      <strong
                        >Recht auf Löschung und Einschränkung der
                        Verarbeitung:</strong
                      >
                      Sie haben nach Maßgabe der gesetzlichen Vorgaben das
                      Recht, zu verlangen, dass Sie betreffende Daten
                      unverzüglich gelöscht werden, bzw. alternativ nach Maßgabe
                      der gesetzlichen Vorgaben eine Einschränkung der
                      Verarbeitung der Daten zu verlangen.
                    </li>
                    <li>
                      <strong>Recht auf Datenübertragbarkeit:</strong> Sie haben
                      das Recht, Sie betreffende Daten, die Sie uns
                      bereitgestellt haben, nach Maßgabe der gesetzlichen
                      Vorgaben in einem strukturierten, gängigen und
                      maschinenlesbaren Format zu erhalten oder deren
                      Übermittlung an einen anderen Verantwortlichen zu fordern.
                    </li>
                    <li>
                      <strong>Beschwerde bei Aufsichtsbehörde:</strong> Sie
                      haben ferner nach Maßgabe der gesetzlichen Vorgaben das
                      Recht, bei einer Aufsichtsbehörde, insbesondere in dem
                      Mitgliedstaat Ihres gewöhnlichen Aufenthaltsorts, Ihres
                      Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes
                      Beschwerde einzulegen, wenn Sie der Ansicht sind, dass die
                      Verarbeitung der Sie betreffenden personenbezogenen Daten
                      gegen die DSGVO verstößt.
                    </li>
                  </ul>
                  <h2 id="m42">Begriffsdefinitionen</h2>
                  <p>
                    In diesem Abschnitt erhalten Sie eine Übersicht über die in
                    dieser Datenschutzerklärung verwendeten Begrifflichkeiten.
                    Viele der Begriffe sind dem Gesetz entnommen und vor allem
                    im Art. 4 DSGVO definiert. Die gesetzlichen Definitionen
                    sind verbindlich. Die nachfolgenden Erläuterungen sollen
                    dagegen vor allem dem Verständnis dienen. Die Begriffe sind
                    alphabetisch sortiert.
                  </p>
                  <ul class="glossary">
                    <li>
                      <strong>Personenbezogene Daten:</strong> "Personenbezogene
                      Daten“ sind alle Informationen, die sich auf eine
                      identifizierte oder identifizierbare natürliche Person (im
                      Folgenden "betroffene Person“) beziehen; als
                      identifizierbar wird eine natürliche Person angesehen, die
                      direkt oder indirekt, insbesondere mittels Zuordnung zu
                      einer Kennung wie einem Namen, zu einer Kennnummer, zu
                      Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder
                      zu einem oder mehreren besonderen Merkmalen identifiziert
                      werden kann, die Ausdruck der physischen, physiologischen,
                      genetischen, psychischen, wirtschaftlichen, kulturellen
                      oder sozialen Identität dieser natürlichen Person sind.
                    </li>
                    <li>
                      <strong>Verantwortlicher:</strong> Als "Verantwortlicher“
                      wird die natürliche oder juristische Person, Behörde,
                      Einrichtung oder andere Stelle, die allein oder gemeinsam
                      mit anderen über die Zwecke und Mittel der Verarbeitung
                      von personenbezogenen Daten entscheidet, bezeichnet.
                    </li>
                    <li>
                      <strong>Verarbeitung:</strong> "Verarbeitung" ist jeder
                      mit oder ohne Hilfe automatisierter Verfahren ausgeführte
                      Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit
                      personenbezogenen Daten. Der Begriff reicht weit und
                      umfasst praktisch jeden Umgang mit Daten, sei es das
                      Erheben, das Auswerten, das Speichern, das Übermitteln
                      oder das Löschen.
                    </li>
                  </ul>
                  <p class="seal">
                    <a
                      href="https://datenschutz-generator.de/?l=de"
                      title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                      >Erstellt mit kostenlosem Datenschutz-Generator.de von Dr.
                      Thomas Schwenke</a
                    >
                  </p>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>

          <v-col v-if="1 == 2" cols="12" sm="12" lg="6">
            <div class="pa-4">
              <h2>Impressum</h2>

              <h3>Angaben gem&auml;&szlig; &sect; 5 TMG</h3>
              <p class="text-body-2">
                Yasin Koschinski<br />
                Siedlerweg 22/2<br />
                88214 Ravensburg
              </p>

              <h3>Kontakt</h3>
              <p class="text-body-2">
                Telefon: +49 (0) 1520 7191712<br />
                E-Mail: yasin@kosy.dev
              </p>
              <h4>Haftung f&uuml;r Inhalte</h4>
              <p class="text-body-2">
                Als Diensteanbieter sind wir gem&auml;&szlig; &sect; 7 Abs.1 TMG
                f&uuml;r eigene Inhalte auf diesen Seiten nach den allgemeinen
                Gesetzen verantwortlich. Nach &sect;&sect; 8 bis 10 TMG sind wir
                als Diensteanbieter jedoch nicht verpflichtet, &uuml;bermittelte
                oder gespeicherte fremde Informationen zu &uuml;berwachen oder
                nach Umst&auml;nden zu forschen, die auf eine rechtswidrige
                T&auml;tigkeit hinweisen.
              </p>
              <p class="text-body-2">
                Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
                Informationen nach den allgemeinen Gesetzen bleiben hiervon
                unber&uuml;hrt. Eine diesbez&uuml;gliche Haftung ist jedoch erst
                ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung
                m&ouml;glich. Bei Bekanntwerden von entsprechenden
                Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
              </p>
            </div>
          </v-col>
          <v-col v-if="1 == 2" cols="12" sm="12" lg="6">
            <div class="pa-4">
              <h2>Sources</h2>
              <ul>
                <li class="break_li text-body-2">
                  Waves Video: Waves Ruhsing and Splashing To The Shore by
                  Michal Marek on pexels.com
                </li>
                <li class="break_li text-body-2">
                  Vue-Logo: Vue.js Logo 2 by Evan You on
                  commons.wikimedia.org/wiki/File:Vue.js_Logo_2.svg (Creative
                  Commons)
                </li>
                <li class="break_li text-body-2">
                  Node-Logo: Node.js Logo Vector on
                  seeklogo.com/vector-logo/273749/node-js (license:
                  nodejs.org/en/about/trademark/)
                </li>
                <li class="break_li text-body-2">
                  git-Logo: Git_icon by Jason Long on
                  commons.wikimedia.org/wiki/File:Git_icon.svg (Creative
                  Commons)
                </li>
                <li class="break_li text-body-2">
                  Python-Logo: Python-logo-notext by python.org from
                  commons.wikimedia.org/wiki/File:Python_logo_and_wordmark.svg
                  (GNU General Public License)
                </li>
                <li class="break_li text-body-2">
                  ROS-Logo: Robot Operating System logo.svg by Chapulinaaa on
                  commons.wikimedia.org/wiki/File:Robot_Operating_System_logo.svg
                  (license: www.ros.org/press-kit/)
                </li>
                <li class="break_li text-body-2">
                  Scrum-Icon: Scrum by Rflor on
                  thenounproject.com/term/scrum/376069/
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-main>
</template>

<script>
export default {
  name: "Main",
  components: {},
  props: {},
  data: () => ({
    panel_opened: 0
  }),
  methods: {
    onResize() {
      if (window.innerHeight < 600) {
        this.height_to_low = true;
      } else {
        this.height_to_low = false;
      }
      let vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
  },
  computed: {
    picture_size() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 125;
        default:
          return 200;
      }
    },
    font_size() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "text-body-2";
        default:
          return "text-body-1";
      }
    }
  },
  mounted() {
    this.onResize();
  }
};
</script>

<style>
#wrapper_imp {
  height: 100vh;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
}
.break_li {
  word-wrap: break-word;
}
</style>
