<template>
  <v-main>
    <div id="wrapper" v-resize="onResize">
      <video loop autoplay muted id="vid">
        <source src="../../public/crashing_waves.mp4" type="video/mp4" />
      </video>
      <div id="filter"></div>
      <v-container
        fill-height
        fluid
        id="content"
        align="center"
        justify="center"
      >
        <v-row>
          <v-col cols="12" class="d-flex flex-column">
            <div class="d-flex justify-center">
              <p
                class="font-weight-light white--text"
                v-bind:class="responsiveFont"
              >
                Welcome!
              </p>
            </div>
            <div class="d-flex justify-center">
              <div id="dividerHolder">
                <v-divider dark />
              </div>
            </div>
            <div class="d-flex justify-center">
              <p class="white--text text-subtitle-1">Portfolio by Yasin K.</p>
            </div>
          </v-col>
        </v-row>
        <div class="btWrapper text-center" v-on:click="emitClick">
          <v-btn fab outlined color="white">
            <v-icon>
              mdi-arrow-down
            </v-icon>
          </v-btn>
        </div>
      </v-container>
    </div>
  </v-main>
</template>

<script>
export default {
  name: "Home",
  components: {},
  props: {},
  data: () => ({}),
  methods: {
    emitClick() {
      this.$emit("toDescription");
    },
    onResize() {
      console.log("Resizing");
      console.log("Window: " + window.innerHeight);
      let vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
  },
  computed: {
    responsiveFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "text-h2";
        default:
          return "text-h1";
      }
    }
  },
  mounted() {
    this.onResize();
  }
};
</script>

<style>
#wrapper {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
}
#vid {
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
}
#filter {
  z-index: 0;
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  background-color: rgba(0, 0, 0, 0.15);
}
#content {
  z-index: 1;
  position: absolute;
  top: 0;
}
.btWrapper {
  position: absolute;
  bottom: 32px;
  left: 0;
  right: 0;
}
#dividerHolder {
  width: 66%;
}
</style>
